import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";

export class JankariRepository {
  constructor() {
    this.appUrl = new AppUrl();
    this.apiService = new ApiService();
  }

  // For Category API
  async createCategory(payload) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/category`;
      return await this.apiService.getPostApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }

  async getCategory(pageNo = 1) {
    try {
      const url = `${this.appUrl.jankariEndPoint}/category/${pageNo}`;
      return await this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }

  async updateCategory(categoryId, payload) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/category/${categoryId}`;
      return await this.apiService.getPatchApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }

  async deleteCategory(categoryId) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/category/${categoryId}`;
      return await this.apiService.getDeleteApiResponse(url);
    } catch (error) {
      throw error;
    }
  }

  // For SubCategory API
  async createSubCategory(payload) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/subCategory`;
      return await this.apiService.getPostApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }

  async getSubCategory(pageNo = 1, categoryId = "*") {
    try {
      const url = `${this.appUrl.jankariEndPoint}/subCategory/${categoryId}/${pageNo}`;
      return await this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }

  async updateSubCategory(subCategoryId, payload) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/subCategory/${subCategoryId}`;
      return await this.apiService.getPatchApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }

  async deleteSubCategory(subCategoryId) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/subCategory/${subCategoryId}`;
      return await this.apiService.getDeleteApiResponse(url);
    } catch (error) {
      throw error;
    }
  }

  // For Post API
  async createPost(payload) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/post`;
      return await this.apiService.getPostApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }

  async getPost(pageNo = "1", subCategoryId = "*") {
    try {
      const url = `${this.appUrl.jankariEndPoint}/post/${subCategoryId}/${pageNo}`;
      return await this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }

  async updatePost(postId, payload) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/post/${postId}`;
      return await this.apiService.getPatchApiResponse(url, payload);
    } catch (error) {
      throw error;
    }
  }

  async deletePost(postId) {
    try {
      const url = `${this.appUrl.adminJankariEndPoint}/post/${postId}`;
      return await this.apiService.getDeleteApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async getSinglePost(postId){
    try{
      const url = `${this.appUrl.publicEndPoint}/post/${postId}`
      return await this.apiService.getGetApiResponse(url)
    }catch(err){
      throw err
    }
  }
}
