import React from "react";
import Component from "../../../components";
import ScreenComponent from "./components";
import useSoilTestingRequestViewModal from "./viewModal";
import ReportScreen from "../report.screen";
const SoilTestingRequests = () => {
  const {
    requests,
    isOpen,
    toggleModal,
    handleSelectedFeild,
    isLoading,
    phoneNumber,
    feilds,
    userName,
    handlePhoneNumber,
    addRequest,
    toggleReportForm,
    selectedFeild
  } = useSoilTestingRequestViewModal();
  return (
    <div className="container">
      <Component.Row justifyContent="space-between" alignItems="center">
        <h1>Requests</h1>
        <Component.Button title="Add Requests" onClick={toggleModal} />
      </Component.Row>
      {isLoading ? (
        <Component.LoadingAnimation />
      ) : (
        <>
          <ScreenComponent.RequestTable
            toggleReportForm={toggleReportForm}
            requests={requests}
          />
        </>
      )}
      <ScreenComponent.AddRequestModal
        isOpen={isOpen}
        toggleModel={toggleModal}
        feilds={feilds}
        userName={userName}
        phoneNumber={phoneNumber}
        handlePhoneNumber={handlePhoneNumber}
        handleSelectedFeild={handleSelectedFeild}
        addRequest={addRequest}
      />
      <ScreenComponent.ReportFormModal
        selectedFeild={selectedFeild}
        toggleReportForm={toggleReportForm}
      />
      <ReportScreen />
    </div>
  );
};

export default SoilTestingRequests;
