import Component from "../../../components";
import styled from "styled-components";
import "./index.css";
import { MdDelete } from "react-icons/md";
import React from "react";
import Fonts from "../../../res/fonts";
import { NavIcons } from "../../../res/assets";

const SearchIcon = styled.button`
  background-color: #f3f6f8;
  height: 50px;
  border-style: none;
  margin: 0;
  padding: 0 10px;
  border-right: 1px solid #00c1a3;
`;

const ConfirmDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  box-shadow: 0px 0px 12px 10px rgba(76, 88, 99, 0.27);
  margin: 30px auto;
  min-width: 40%;
  height: 250px;
  padding: 30px 10px;
  border: 2px solid #ffa5a5;
  border-radius: 6px;
  backdrop-filter: blur(2px);
  background: rgba(0, 193, 163, 0.8);
`;

const ConfirmDelete = ({
  handleDeleteModal,
  handleDelete,
  selectedWorkerId,
}) => {
  return (
    <ConfirmDeleteContainer>
      <div className="deleteContainerChild">
        <Fonts.MediumFont fontSize={28} color="#ffffff">
          Confirm Delete
        </Fonts.MediumFont>
        <Fonts.RegularFont fontSize={22} color="#ffffff">
          Are you sure want to delete !
        </Fonts.RegularFont>
        <div className="deleteButtonContainer">
          <Component.AuthButton
            text={"Cancle"}
            width={130}
            height={50}
            onClick={handleDeleteModal}
          />
          <Component.AuthButton
            text={"Delete"}
            width={130}
            height={50}
            color={"#DB222A"}
            onClick={() => handleDelete(selectedWorkerId)}
          />
        </div>
      </div>
    </ConfirmDeleteContainer>
  );
};

export const SearchButton = ({ onClick }) => {
  return (
    <SearchIcon onClick={onClick}>
      <NavIcons.SearchIcon />
    </SearchIcon>
  );
};
const AddUserModel = ({
  isOpen,
  toggleModel,
  newEmployee,
  getnewEmpDetails,
  addUser,
}) => {
  return (
    <Component.Model open={isOpen} height={300} width={400} padding={10}>
      <h2 style={{ margin: 0 }}> Add Users </h2>
      <Component.Spacer position="top" size={20} />
      <Component.Row>
        <Component.DailogBoxInput
          name="name"
          value={newEmployee.name}
          onChange={getnewEmpDetails}
          placeholder="Employee Name"
          type="text"
        />
      </Component.Row>
      <Component.Spacer position="top" size={40} />
      <Component.Row>
        <Component.DailogBoxInput
          name="id"
          value={newEmployee.id}
          onChange={getnewEmpDetails}
          placeholder="Employee Id"
          type="number"
        />
      </Component.Row>
      <Component.Spacer position="top" size={40} />
      <Component.Row>
        <select
          name="roles"
          className="selectUser"
          value={newEmployee.roles}
          onChange={getnewEmpDetails}
        >
          <option defaultChecked value="">
            {" "}
            select User
          </option>
          <option value="Admin" id="Admin">
            Admin
          </option>
          <option value="User" id="Userd">
            User
          </option>
        </select>
      </Component.Row>
      <Component.Spacer position="top" size={20} />
      <Component.Row justifyContent="flex-end">
        <Component.Button title="Cancel" color="red" onClick={toggleModel} />
        <Component.Spacer position="right" size={10} />
        <Component.Button title="Add" onClick={addUser} />
      </Component.Row>
    </Component.Model>
  );
};
export const CardBody = ({ worker }) => {
  return (
    <div style={{ padding: 8 }}>
      <Component.Row justifyContent="center">
        <img
          className="profileImageUserCard"
          alt="profileImage"
          src={worker.profileImage}
        />
      </Component.Row>
      <Component.Row justifyContent="center">
        {/* <Fonts.RegularFont>ID -</Fonts.RegularFont> */}
        <Component.Spacer position={"left"} size={10} />
        <Fonts.RegularFont>{worker.userHandler}</Fonts.RegularFont>
      </Component.Row>
      <Component.Row justifyContent="center">{worker.email}</Component.Row>
      <Component.Row
        style={{ margin: "10px" }}
        justifyContent="center"
        alignItems="center"
      >
        <h3
          style={{ margin: "0", color: !worker.isDeleted ? "#00C1A3" : "red" }}
        >
          {worker.isDeleted ? (
            <Component.Column alignItems="center">
              <div> Deleted </div>
              <div style={{textAlign: "center" , fontSize: 12}}> {worker.reasonForDelete} </div>
            </Component.Column>
          ) : (
            "Active"
          )}
        </h3>
      </Component.Row>
    </div>
  );
};

export const CardFooter = ({ worker, handleDelete, handleDeleteModal }) => {
  return (
    <div className="cardFooter">
      <Component.Row
        style={{ height: "100%" }}
        justifyContent="flex-end"
        alignItems="center"
      >
        <div style={{ margin: "0", color: worker.roles === "Admin" ? "#00C1A3" : "red" }}>
          <h3>{worker.roles}</h3>
        </div>
       
      </Component.Row>
    </div>
  );
};
export default {
  AddUserModel,
  CardBody,
  CardFooter,
  ConfirmDelete,
};
