import { useState, useEffect } from "react";
import { JankariRepository } from "../../../repository/jankari.repository";
import { FileUploadRepository } from "../../../repository/fileUpload.repository";
import { useAlert } from "react-alert";
import { useRef } from "react";
const usePostViewModel = () => {
  const alert = useAlert();
  const fileuploadRepository = new FileUploadRepository();
  const jankariRepository = new JankariRepository();
  const [isOpen, setIsOppen] = useState(false);
  const [postData, setPostData] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [postImage, setPostImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [subCategoryId, setSubCategoryId] = useState("*");
  const [postId, setPostId] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [postInfo, setPostInfo] = useState(null);
  const quillRef = useRef(null);
  const [descriptionImages, setDescriptionImages] = useState([]);
  const toggleModel = async () => {
    try {
      setIsOppen(!isOpen);
      if (isOpen === false) {
        const response = await jankariRepository.getSubCategory("*", "*");
        setCategoryList(response.subCategories);
      } else {
        setPostData(null);
        setPostImage(null);
        setPostId(null);
        setIsUpdate(false);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };
  // text editor for html

  const renderEditorImage = (imgurl) => {
    // Data URL of the image
    if (quillRef.current && imgurl) {
      const quillEditor = quillRef.current.getEditor();
      const range = quillEditor.getSelection();
      quillEditor.insertEmbed(range?.index, "image", imgurl, {
        style: "height: 200px; width: 200px;", // Added "px" units for width
      });
    }
  };
  
  const handleImageInsert = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.addEventListener("change", async (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        try {
          const data = new FormData();
          data.append("image", selectedFile);
          const imgurl = await uploadImageToServer(data)
          renderEditorImage(imgurl); // Insert image in the editor
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    });
  };

  const uploadImageToServer = async (formData) => {
    try {
      const response = await fileuploadRepository.uploadImage(formData);
      console.log(response)
      if (response) {
        return response.imgurl;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      alert.error(error.message);
      throw error;
    }
  };

  const getPostData = (event) => {
    try {
      setPostData({ ...postData, [event.target.name]: event.target.value });
    } catch (error) {
      alert.error(error.message);
    }
  };
  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          toggleModel();
          const error = new Error("image size should be less than 1MB");
          error.statusCode = 400;
          throw error;
        }
        const data = new FormData();
        data.append("image", file);
        setPostImage(data);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };
  const uploadPost = async () => {
    try {
      if (postData && postImage) {
        if (
          postData.title &&
          postData.description &&
          postData.subCategoryId &&
          postData.hindiTitle &&
          postData.hindiDescription
        ) {
          setIsLoading(true);
          const response = await fileuploadRepository.uploadImage(postImage);
          await jankariRepository.createPost({
            ...postData,
            imgUrl: response.imgurl,
          });
          setIsLoading(false);
          toggleModel();
          setPostData({});
          setDescriptionImages([]);
          getPosts(pageNo, subCategoryId);
          setPostImage(null);
        }
      }
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const getPosts = async (pageNo = 1, subCategoryId = "*") => {
    try {
      setIsLoading(true);
      setPageNo(pageNo);
      const response = await jankariRepository.getPost(pageNo, subCategoryId);
      setPosts(response.posts);
      setIsLoading(false);
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const toggleEditModel = (post_id) => {
    return () => {
      try {
        const post = posts.find((ele) => ele._id === post_id);
        setPostData(post);
        setPostId(post._id);
        setIsUpdate(!isUpdate);
        toggleModel();
      } catch (error) {
        alert.error(error.message);
      }
    };
  };

  const editPOst = async () => {
    try {
      setIsLoading(true);
      if (postId) {
        if (postImage) {
          const response = await fileuploadRepository.uploadImage(postImage);
          await jankariRepository.updatePost(postId, {
            ...postData,
            imgUrl: response.imgurl,
          });
        } else {
          await jankariRepository.updatePost(postId, { ...postData });
        }
        toggleModel();
        getPosts(pageNo, subCategoryId);
        setIsLoading(false);
        setDescriptionImages([]);
        setPostId(null);
      }
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const toggleDeleteRequest = (post_id = null) => {
    return () => {
      try {
        if (post_id) {
          setPostId(post_id);
          setDeleteAlert(true);
        } else {
          setDeleteAlert(false);
        }
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  const previousPage = async () => {
    try {
      if (pageNo > 1) await getPosts(pageNo - 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const nextPage = async () => {
    try {
      if (posts.length > 0) await getPosts(pageNo + 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const deletePost = async () => {
    try {
      setIsLoading(true);
      if (postId) {
        await jankariRepository.deletePost(postId);
        toggleDeleteRequest();
        setDeleteAlert(false);
        getPosts(pageNo, subCategoryId);
      }
      setIsLoading(false);
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };
  const togglePostInfoModel = (postId) => {
    return () => {
      try {
        const post = posts.find((ele) => ele._id === postId);
        if (post) setPostInfo(post);
        else setPostInfo(null);
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  useEffect(() => {
    try {
      getPosts(pageNo, subCategoryId);
    } catch (error) {
      alert.error(error.message);
    }
  }, [pageNo, subCategoryId]);

  return {
    toggleModel,
    isOpen,
    postData,
    getPostData,
    categoryList,
    handleFileChange,
    postImage,
    uploadPost,
    posts,
    isLoading,
    toggleEditModel,
    editPOst,
    deleteAlert,
    toggleDeleteRequest,
    deletePost,
    isUpdate,
    pageNo,
    nextPage,
    previousPage,
    postInfo,
    togglePostInfoModel,
    quillRef,
    handleImageInsert,
  };
};
export default usePostViewModel;
