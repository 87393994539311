import Component from "../../../components";
import React from "react";

const AddEmployeeModal = ({
  isOPen,
  toggleModal,
  handleEmployeeId,
  handleEmployeeRole,
  handleEmployeeName,
  handleEmloyeeEmail,
   isLoading,
  createEmployee
}) => {
  return (
    <Component.DailogBox
      height={300}
      width={500}
      toggleModel={toggleModal}
      isOpen={isOPen}
    >
   {isLoading ? 
   <Component.LoadingAnimation  color={"green"} />
   :
   <>
         <h1>Add Employee</h1>
      <Component.AuthInputField type="text" onChange={handleEmployeeId} placeholder="Employee ID" />
      <Component.Spacer position={"top"} size={20} />
      <Component.AuthInputField type="text" onChange={handleEmployeeName} placeholder="Employee Name" />
      <Component.Spacer position={"top"} size={20} />
      <Component.AuthInputField type="text" onChange={handleEmloyeeEmail} placeholder="Employee Email" />
      <Component.Spacer position={"top"} size={20} />
      <select placeholder="Select Employee Role" onChange={handleEmployeeRole}>
        <option value={"Admin"}>Admin</option>
        <option value={"Moderator"}>Moderator</option>
        <option value={"Agri_Scintist"}> Agri Scintist</option>
      </select>
      <Component.Spacer position={"top"} size={20} />
      <Component.Button title="Add New Employee" onClick={createEmployee} />
   </>

   }
    </Component.DailogBox>
  );
};

export default {
  AddEmployeeModal,
};
