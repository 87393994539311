import Component from "../../../components";
import "../crops.css";
const CropModal = ({
  toggleModal,
  open,
  getNewCropsData,
  handleFileChange,
  createCrops,
  cropCategories,
  newCropName,
  handleNewCropCategory
}) => {
  return (
    <Component.Model width={500} height={300} open={open}>
     <Component.Row justifyContent="space-between" alignItems="center">
     <h2 className="cropModalHeaders"> Add Crop</h2>
     <select name="cropCategory" onChange={handleNewCropCategory}>
     <option value = {""}>  Select Category </option>

     {cropCategories.map((category) => {
                return (
                  <option value = {category._id}> {category.name} </option>
                );
              })
            }
     </select>
     </Component.Row>
      <Component.Column>
        <h3 className="cropModalHeaders">Name</h3>
        <Component.AuthInputField
          name="name"
          value={newCropName}
          onChange={getNewCropsData}
          placeholder={"Crop Name"}
        />
          <h3 className="cropModalHeaders">Name in Hindi</h3>
          <Component.AuthInputField
          name="name_hi"
          value={newCropName}
          onChange={getNewCropsData}
          placeholder={"Crop Name"}
        />
        <h3 className="cropModalHeaders">Image</h3>
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/png, image/jpeg"
          placeholder="Any Image for Suggestions"
          name="image"
        />

      </Component.Column>
      <Component.Spacer position={"top"} size={30} />
      <Component.Row justifyContent="flex-end" alignItems="center">
        <Component.Button title="Cancel" onClick={toggleModal} />
        <Component.Spacer position={"right"} size={20} />
        <Component.Button title="Add" onClick={createCrops} />
      </Component.Row>
    </Component.Model>
  );
};
const CropCategoryModal = ({
  isAddCategoryOpen,
  createNewCrategory,
  toggleAddCategoryModal,
  handleNewCropCategory
}) => {
  return (
    <Component.Model width={500} height={300} open={isAddCategoryOpen}>
      <h3>Add Category</h3>
      <Component.AuthInputField
      name="name"
        onChange={handleNewCropCategory} 
        placeholder={"Crop Name"}
      />
       <h3>Add Category in hindi</h3>
      <Component.AuthInputField
      name="name_hi"
        onChange={handleNewCropCategory} 
        placeholder={"Crop Name"}
      />
      <Component.Row justifyContent="flex-end" alignItems="center">
        <Component.Button title="Cancel" onClick={toggleAddCategoryModal}  />
        <Component.Spacer position={"right"} size={20} />
        <Component.Button title="Add" onClick={createNewCrategory}  />
      </Component.Row>
    </Component.Model>
  );
};
export default {
  CropModal,
  CropCategoryModal,
};
