import Component from "../../../components";
import "./index.css";
const ChatReplyModel = ({
  isOpen,
  toggleModel,
  targetQuestion = "how are you",
  answer,
  getAnswer,
  reply,
}) => {
  return (
    <Component.Model
      open={isOpen}
      height={targetQuestion.userImageAttachment ? 620 : 450}
      width={targetQuestion.userImageAttachment ? 600 : 400}
      padding={10}
    >
      <h2 style={{ margin: 0 }}> Question </h2>
      <Component.Spacer position="top" size={20} />
      <Component.Column flexWrap="wrap">
        <img
          className="imageQuestion"
          src={targetQuestion.userImageAttachment}
          alt="image not aaalable"
        />

        <spam> {targetQuestion.userMessage} </spam>
      </Component.Column>
      <Component.Spacer position="top" size={20} />
      <Component.Row>
        <textarea
          type="text"
          onChange={getAnswer}
          value={answer.answer}
          className="chatTextBox"
          name="adminReply"
          placeholder="Type answer"
        ></textarea>
      </Component.Row>
      <Component.Spacer position="top" size={20} />
      <Component.Row alignItems="center">
        <spam> Image </spam>
        <Component.Spacer position="right" size={20} />
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={getAnswer}
          placeholder="Any Image for Suggestions"
          name="image"
        />
      </Component.Row>
      <Component.Spacer position="top" size={20} />
      <Component.Row alignItems="center">
        <spam> Suggestion Link</spam>
        <Component.Spacer position="right" size={20} />
        <Component.DailogBoxInput
          onChange={getAnswer}
          value={answer.url}
          name="adminRepliedUrl"
          type="text"
          placeholder="Any Link for Suggestion"
        />
      </Component.Row>
      <Component.Spacer position="top" size={20} />
      <Component.Row justifyContent="flex-end">
        <Component.Button title="Cancel" color="red" onClick={toggleModel()} />
        <Component.Spacer position="right" size={10} />
        <Component.Button title="Reply " onClick={reply} />
      </Component.Row>
    </Component.Model>
  );
};
export default {
  ChatReplyModel,
};
