import React from "react";
import ScreenComponents from "./components";
import { useUserIntrestViewModal } from "./intrest.viewModal";
import Component from "../../components";
export default function UserIntrestScreen() {
  const { intrustedData, pageNo, previousPage, nextPage } =
    useUserIntrestViewModal();
  return (
    <div className="container">
      <h1> Intrested User </h1>
      <div>
        {intrustedData.length > 0 ? (
          <ScreenComponents.IntrestTable intrustedData={intrustedData} />
        ) : (
          <h1> Nothing To Show </h1>
        )}

        <div className="pagination">
          <Component.ChildContainer justifyContent="center">
            <Component.PageNavigator
              previousPage={previousPage}
              nextPage={nextPage}
            >
              {pageNo}
            </Component.PageNavigator>
          </Component.ChildContainer>
        </div>
      </div>
    </div>
  );
}
