import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import QRCode from 'qrcode';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  qrCode: {
    marginVertical: 15,
    alignSelf: 'center'
  }
});

const PDFDoc = ({ id, date, qrCode }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>ID: {id}</Text>
        <Image src={qrCode} style={styles.qrCode} />
        <Text>Created on: {date}</Text>
      </View>
    </Page>
  </Document>
);

const DownloadPDFButton = ({ id, date }) => {
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    QRCode.toDataURL(id, { errorCorrectionLevel: 'H' }, (err, url) => {
      if (err) console.error(err);
      setQrCode(url);
    });
  }, [id]);

  return qrCode ? (
    <PDFDownloadLink document={<PDFDoc id={id} date={date} qrCode={qrCode} />} fileName={`${id}.pdf`}>
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download as PDF')}
    </PDFDownloadLink>
  ) : null;
};

export default DownloadPDFButton;