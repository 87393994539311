import { useEffect, useState } from "react"
import { FeedRepository } from "../../../repository/feed.repository";
import { useAlert } from "react-alert";
const useFeedCategoryViewModel = () => {
  const feedRepository = new FeedRepository()
  const alert = useAlert()
  const [categoryList, setCategoryList] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false)
  const [category, setCategory] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [showDeleteAlert, setDeleteAlert] = useState(false)
  const [categoryInHindi, setCategoryInHindi] = useState(category)

  const { t } ="apple";
  const toggleModel = () => {
    try {
      setIsOpen(!isOpen);
      if (isOpen) {
        setIsUpdate(false);
        setCategory(null);
        setCategoryInHindi(null);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };
  
  const getCategory = (event) => {
    try {
      const text = event.target.value.trim();
      setCategory(event.target.value);
      setCategoryInHindi(t(text.toLowerCase()));
    } catch (error) {
      alert.error(error.message);
    }
  };
  
  const editCategoryInHindi = (event) => {
    try {
      setCategoryInHindi(event.target.value);
    } catch (error) {
      alert.error(error.message);
    }
  };
  
  const getCategories = async () => {
    try {
      setIsLoading(true);
      const response = await feedRepository.getCategories();
      setCategoryList(response.categories);
      setIsLoading(false);
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };
  
  const createCategory = async () => {
    try {
      if (category && categoryInHindi) {
        await feedRepository.createCategory({ category, categoryInHindi });
        toggleModel();
        getCategories();
        alert.success("Created Successfully");
      }
    } catch (err) {
      alert.error(err.message);
    }
  };
  
  const toggleEditModel = (categoryId) => {
    return () => {
      try {
        setIsUpdate(true);
        const targetCategory = categoryList.find(
          (category) => category._id === categoryId
        );
        setCategory(targetCategory.category);
        setCategoryInHindi(targetCategory.categoryInHindi);
        setCategoryId(categoryId);
        toggleModel();
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  
  const editCategory = async () => {
    try {
      await feedRepository.updateCategory(categoryId, {
        category,
        categoryInHindi,
      });
      toggleModel();
      getCategories();
      alert.success("Updated Successfully");
    } catch (err) {
      alert.error(err.message);
    }
  };
  
  const toggleDeleteRequest = (categoryId = null) => {
    return () => {
      try {
        if (showDeleteAlert) {
          setDeleteAlert(false);
          setCategoryId(null);
        } else {
          setDeleteAlert(true);
          setCategoryId(categoryId);
        }
      } catch (error) {
        alert.error(error.message);
      }
    };
  };
  
  const deleteCategory = async () => {
    try {
      await feedRepository.deleteCategory(categoryId);
      setDeleteAlert(false);
      setCategoryId(null);
      getCategories();
      alert.success("Deleted Succesfully");
    } catch (err) {
      alert.error(err.message);
    }
  };
  
  useEffect(() => {
    try {
      getCategories();
    } catch (error) {
      alert.error(error.message);
    }
  }, []);
  
  return {
    categoryList,
    isLoading,
    isOpen,
    toggleModel,
    category,
    getCategory,
    createCategory,
    toggleEditModel,
    editCategory,
    isUpdate,
    toggleDeleteRequest,
    deleteCategory,
    showDeleteAlert,
    categoryInHindi,
    editCategoryInHindi
  }
}
export default useFeedCategoryViewModel