import { useEffect, useState } from "react";
import { AgriStickRepository } from "../../repository/agriStick.repository";
import { useAlert } from "react-alert";

export const useUserIntrestViewModal = () => {
  const [intrustedData, setIntrustedData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const alert = useAlert();
  const getUsrIntrestData = async (page = 1) => {
    try {
      const apiResponse = await AgriStickRepository.getUserInterest(page);

      if (apiResponse.data) {
        setIntrustedData(apiResponse.data);
        setPageNo(page);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const previousPage = async () => {
    try {
      if (pageNo > 1) await getUsrIntrestData(pageNo - 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const nextPage = async () => {
    try {
      if (intrustedData.length > 0) await getUsrIntrestData(pageNo + 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  useEffect(() => {
    getUsrIntrestData();
  }, []);
  return {
    intrustedData,
    previousPage,
    nextPage,
    pageNo,
  };
};
