import Component from "../../components";
import useChatViewModel from "./chatViewModel";
import ScreenComponent from "./components";
import { Link } from "react-router-dom";
import "./chat.css";
const Chat = () => {
  const {
    chatMessages,
    isLoading,
    isOpen,
    targetQuestion,
    answer,
    getAnswer,
    toggleModel,
    pageNo,
    nextPage,
    previousPage,
    reply,
  } = useChatViewModel();
  return (
    <div className="container">
      <Component.Row
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <h1> Chat ({chatMessages.length}) </h1>
        <Link to="/botScript">
          <Component.Button title="Bot Script" />
        </Link>
      </Component.Row>
      <Component.Spacer position="top" size={40} />
      <ScreenComponent.ChatReplyModel
        isOpen={isOpen}
        toggleModel={toggleModel}
        targetQuestion={targetQuestion}
        answer={answer}
        getAnswer={getAnswer}
        reply={reply}
      />
      <Component.Row justifyContent="center" alignItems="center">
        {isLoading ? (
          <Component.LoadingAnimation color="green" />
        ) : (
          <>
            {chatMessages.length > 0 ? (
              // <table className="chatTable" border="1">
              //   <thead>
              //     <tr>
              //       <th colSpan="3">Questions</th>
              //     </tr>
              //   </thead>
              //   <tbody>
              //     {chatMessages.map((chat) => {
              //       return (
              //         <tr key={chat._id}>
              //           <td>
              //             <Component.Row
              //               justifyContent="space-between"
              //               alignItems="center"
              //             >
              //               <Component.Row style={{ padding: "10px" }}>
              //                 <img
              //                   className="profileImage"
              //                   src={chat.user.profileImage}
              //                   alt="something went wrong"
              //                 />
              //                 <Component.Column alignItems="flex-start">
              //                   <p className="userName"> {chat.user.name} </p>
              //                   <p> {chat.user.userHandler} </p>
              //                   {chat.imageQuestion ? (
              //                     <>
              //                       {chat.imageQuestion && (
              //                         <img
              //                           className="questionIMage"
              //                           src={chat.imageQuestion}
              //                           alt=" not avalable"
              //                         />
              //                       )}
              //                     </>
              //                   ) : (
              //                     <spam> {chat.question} </spam>
              //                   )}
              //                 </Component.Column>
              //               </Component.Row>
              //               <Component.Row alignItems="center">
              //                 <Component.Button
              //                   title="visit"
              //                   onClick={toggleModel(chat, chat._id)}
              //                 />
              //                 <Component.Spacer position="right" size={10} />
              //               </Component.Row>
              //             </Component.Row>
              //           </td>
              //         </tr>
              //       );
              //     })}
              //   </tbody>
              // </table>
              <Component.Row justifyContent="center" alignItems="center">
                <table className="dashboardTable" border="1">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Farmer Name</th>
                      <th> Phone No.</th>
                      <th>Village</th>
                      <th>District</th>
                      <th>State</th>
                      <th>Age</th>
                      <th>Crop</th>
                      <th>Problem Section</th>
                      <th>Problem's Pic or Comment by User</th>
                      <th> User Attachment</th>
                      <th>Solution by Agriculturist</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example Row, replace with dynamic content as needed */}
                    {chatMessages.map((chat, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{chat?.user?.name}</td>
                          <td>{chat?.user?.phoneNumber}</td>
                          <td>{chat?.user?.village}</td>
                          <td>{chat?.user?.district_en}</td>
                          <td>{chat?.user?.state}</td>
                          <td>{chat?.ageGroup}</td>
                          <td>{chat?.crop}</td>
                          <td>{chat?.problemSection}</td>
                          <td>{chat?.userMessage}</td>
                          <td>
                            {chat?.userImageAttachment && (
                              <img
                                className="userImage"
                                src={chat?.userImageAttachment}
                                alt="Image not found"
                              />
                            )}
                          </td>
                          <td>
                            {chat?.adminReply ? (
                              <p>{chat.adminReply}</p>
                            ) : (
                              <button onClick={toggleModel(chat, chat._id)}>
                                Reply this problem
                              </button>
                            )}
                          </td>
                          <td>
                            {chat?.isOpened && chat?.isReplied
                              ? "User opened the solution"
                              : chat?.isReplied
                              ? "Replied"
                              : "Not replied yet"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Component.Row>
            ) : (
              <h1> No chat avalable to reply</h1>
            )}
          </>
        )}
        <div className="pagination">
          <Component.ChildContainer justifyContent="center">
            <Component.PageNavigator
              previousPage={previousPage}
              nextPage={nextPage}
            >
              {pageNo}
            </Component.PageNavigator>
          </Component.ChildContainer>
        </div>
      </Component.Row>
    </div>
  );
};
export default Chat;
