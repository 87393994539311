import Component from "../../../components"
import ScreenComponent from "./components"
import usePostViewModel from "./postViewModel"
import HTMLRenderer from 'react-html-renderer';
const Post = () => {
    const {
        isOpen,
        toggleModel,
        postData,
        getPostData,
        categoryList,
        handleFileChange,
        postImage,
        uploadPost,
        isLoading,
        posts,
        toggleEditModel,
        editPOst,
        deleteAlert,
        toggleDeleteRequest,
        deletePost,
        isUpdate,
        pageNo,
        nextPage,
        previousPage,
        togglePostInfoModel,
        postInfo, 
        quillRef,
        handleImageInsert
    } = usePostViewModel()
    return (
        <div className="container" >
            <Component.DeleteAlert cancelFunction={toggleDeleteRequest()} dleteFunction={deletePost} open={deleteAlert} >
                Do you want to delete this Post
            </Component.DeleteAlert>
            <Component.Row flexWrap="wrap" justifyContent='space-between' alignItems='center' >
                <h1>   Posts  </h1>
                <Component.Button title="Add Post" onClick={toggleModel} />
            </Component.Row>
            <ScreenComponent.PostModel
                isOpen={isOpen}
                toggleModel={toggleModel}
                postData={postData}
                getPostData={getPostData}
                categoryList={categoryList}
                handleFileChange={handleFileChange}
                postImage={postImage}
                uploadPost={uploadPost}
                editPOst={editPOst}
                isLoading={isLoading}
                isUpdate={isUpdate}
                quillRef={quillRef}
                handleImageInsert={handleImageInsert}
            />
            <Component.ChildContainer className='cardContainer' justifyContent="center" flexWrap="wrap" >
                {
                    isLoading ? (
                        <Component.LoadingAnimation color="green" />
                    ) : (
                        <>
                            {
                                posts.length <= 0 ? (
                                    <h1> Nothing To Show </h1>
                                ) : (
                                    <>
                                        {
                                            posts.map((post) => {
                                              
                                                return (
                                                    <Component.GridCard
                                                        name={post.hindiTitle}
                                                        key={post._id}
                                                        isPost= {true}
                                                        imgurl={post.imgUrl}
                                                        likes={post.likes}
                                                        comments={post.disLikes}
                                                        description={post.hindiDescription}
                                                        getInfo={togglePostInfoModel(post._id)}
                                                        dropDownOptions={[
                                                            {
                                                                title: "Edit",
                                                                method: toggleEditModel(post._id)
                                                            },
                                                            {
                                                                title: "Delete",
                                                                method: toggleDeleteRequest(post._id)
                                                            }
                                                        ]}
                                                    />
                                                )
                                            })
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    postInfo && (
                        <Component.InfoModel
                            title={postInfo.hindiTitle}
                         
                            isOpen={postInfo ? true : false}
                            image={postInfo.imgUrl}
                            description={ <HTMLRenderer html={postInfo.hindiDescription} />}
                            toggleModel={togglePostInfoModel(null)}
                        />
                    )
                }
            </Component.ChildContainer>
            <div className="pagination">
                <Component.ChildContainer justifyContent="center" >
                    <Component.PageNavigator previousPage={previousPage} nextPage={nextPage}>
                        {pageNo}
                    </Component.PageNavigator>
                </Component.ChildContainer>
            </div>
        </div>
    )
}
export default Post;