import React from "react";
import Component from "../../../components/index";
import "./Feeds.css"; // Create a CSS file to style the component
import FeedViewModel from "./feedsViewModel";
import ScreenComponent from "./components/index";
const Feed = () => {
  const {
    isOpen,
    toggleModel,
    addFeed,
    handleFileChange,
    imgurl,
    isLoading,
    feeds,
    caption,
    upload,
    getFeedCaption,
    pageNo,
    previousPage,
    nextPage,
    openEditFeedModel,
    editFeed,
    isUpdate,
    toggleDeleteRequest,
    showDeleteAlert,
    deleteFeed,
    commentsOnFeed,
    changeCategory,
    category,
    categoryList,
    chageUploadedBy,
    toggleApproval,
    approve,
    approval,
    comments,
    toggleCommentModel,
    toggleInfoModel,
    feedInfo,
    commentOnFeed,
    comment,
    getComment,
    commentModel,
    youTubeUrl,
    handleYouTubeUrl,
    mediaType,

    videoUrl,
  } = FeedViewModel();

  return (
    <div className="container">
      <Component.DeleteAlert
        cancelFunction={toggleDeleteRequest()}
        dleteFunction={deleteFeed}
        open={showDeleteAlert}
      >
        Do you want to delete this feed
      </Component.DeleteAlert>
      <Component.Row
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1> Feeds ({feeds.length}) </h1>
        <Component.Row flexWrap="wrap" alignItems="center" f>
          <Component.Row alignItems="center" flexWrap="wrap">
            <span> Category</span>
            <Component.Spacer position="right" size={10} />
            <select onChange={changeCategory} value={category}>
              <option value="*"> All</option>
              {categoryList.map((category) => {
                return (
                  <option value={category._id}> {category.category} </option>
                );
              })}
            </select>
          </Component.Row>
          <Component.Spacer position="right" size={20} />
          <Component.Row alignItems="center" flexWrap="wrap">
            <span> Role</span>
            <Component.Spacer position="right" size={10} />
            <select onChange={chageUploadedBy}>
              <option defaultChecked value="*" id="All">
                {" "}
                All
              </option>
              <option value="Admin" id="Admin">
                Admin
              </option>
              <option value="Moderator" id="Moderator">
                Moderator
              </option>
              <option value="User" id="Userd">
                User
              </option>
            </select>
          </Component.Row>
          <Component.Spacer position="right" size={20} />
          <Component.Button title="Create New Feed" onClick={toggleModel}>
            Open Model
          </Component.Button>
        </Component.Row>
      </Component.Row>
      {isOpen && (
        <ScreenComponent.FeedModel
          isOpen={isOpen}
          videoUrl={videoUrl}
          toggleModel={toggleModel}
          handleFileChange={handleFileChange}
          addFeed={addFeed}
          imgurl={imgurl}
          mediaType={mediaType}
          youTubeUrl={youTubeUrl}
          caption={caption}
          isLoading={isLoading}
          handleYouTubeUrl={handleYouTubeUrl}
          getFeedCaption={getFeedCaption}
          upload={upload}
          editFeed={editFeed}
          isUpdate={isUpdate}
          categoryList={categoryList}
          category={category}
          changeCategory={changeCategory}
        />
      )}
      <Component.Row>
        <div>
          <input
            type="radio"
            defaultChecked
            onClick={toggleApproval}
            value="approved"
            name="approval"
            id="approved"
          />
          <label htmlFor="approved"> Approved</label>
        </div>
        <Component.Spacer position="right" size={20} />
        <div>
          <input
            type="radio"
            onClick={toggleApproval}
            value="unapproved"
            name="approval"
            id="unapproved"
          />
          <label htmlFor="unapproved"> Unapproved</label>
        </div>
      </Component.Row>
      <Component.ChildContainer
        className="cardContainer"
        justifyContent="center"
        flexWrap="wrap"
      >
        {isLoading ? (
          <Component.LoadingAnimation color="green" />
        ) : (
          <>
            {feeds.length <= 0 ? (
              <h1> Nothing To Show </h1>
            ) : (
              <>
                {feeds.map((feed) => {
                  // let imageUrl =  feed.imgurl.split('/')[3]
                  // imageUrl = `https://d336izsd4bfvcs.cloudfront.net/${imageUrl}`
                  // let profile = feed.user.profileImage.split('/')[3]
                  // profile = `https://d336izsd4bfvcs.cloudfront.net/${profile}`
                  return (
                    <Component.GridCard
                      isFeed={true}
                      key={feed._id}
                      profile={feed.user.profileImage}
                      name={feed.user.name}
                      imgurl={feed.imgurl}
                      videoUrl={feed.videoUrl}
                      mediaType={feed.mediaType}
                      tag={feed.user.userHandler}
                      likes={feed.likes.length}
                      comments={feed.comments.length}
                      isApproved={approval === "approved" ? true : false}
                      onApprove={
                        approval === "approved" ? null : approve(feed._id)
                      }
                      getInfo={toggleInfoModel(feed._id)}
                      description={feed.hindiCaption}
                      dropDownOptions={
                        feed.user.roles === "User"
                          ? [
                              {
                                title: "Delete",
                                method: toggleDeleteRequest(feed._id),
                              },
                              {
                                title: "Comments",
                                method: commentsOnFeed(feed._id),
                              },
                            ]
                          : [
                              {
                                title: "Edit",
                                method: openEditFeedModel(feed._id),
                              },
                              {
                                title: "Delete",
                                method: toggleDeleteRequest(feed._id),
                              },
                              {
                                title: "Comments",
                                method: commentsOnFeed(feed._id),
                              },
                            ]
                      }
                    />
                  );
                })}
              </>
            )}
          </>
        )}
        <ScreenComponent.CommentModel
          comments={comments}
          toggleCommentModel={toggleCommentModel}
          comment={comment}
          commentOnFeed={commentOnFeed}
          getComment={getComment}
          commentModel={commentModel}
        />
        {feedInfo && (
          <Component.InfoModel
            title={feedInfo.user.name}
            isOpen={feedInfo ? true : false}
            profile={feedInfo.user.profileImage}
            image={feedInfo.imgurl}
            video={feedInfo.videoUrl}
            mediaType={feedInfo.mediaType}
            description={feedInfo.hindiCaption}
            toggleModel={toggleInfoModel(null)}
          />
        )}
      </Component.ChildContainer>
      <div className="pagination">
        <Component.ChildContainer justifyContent="center">
          <Component.PageNavigator
            previousPage={previousPage}
            nextPage={nextPage}
          >
            {pageNo}
          </Component.PageNavigator>
        </Component.ChildContainer>
      </div>
    </div>
  );
};

export default Feed;
