export class AppUrl {
  constructor() {
    this.baseUrl = true
      ? "https://agrichikitsa.org/api/v1"
      : "http://localhost:9090/api/v1";
    this.authEndPoint = `${this.baseUrl}/auth`;
    this.fileUploadEndPoint = `${this.baseUrl}/upload`;
    this.feedEndPoint = `${this.baseUrl}/feed`;
    this.adminFeedEndPoint = `${this.baseUrl}/admin/feed`;
    this.adminJankariEndPoint = `${this.baseUrl}/admin/jankari`;
    this.jankariEndPoint = `${this.baseUrl}/jankari`;
    this.adminChatEndPoint = `${this.baseUrl}/admin/chat`;
    this.chatEndPoint = `${this.baseUrl}/chat`;
    this.notificationsEndPoint = `${this.baseUrl}/notification`;
    this.userEndPoint = `${this.baseUrl}/users`;
    this.publicEndPoint = `${this.baseUrl}/public`;
    this.agriStickEndPoint = `${this.baseUrl}/agristick`;
    this.soilTestingEndPoint = `${this.baseUrl}/soilTesting`;
    this.reportEndPoint = `${this.baseUrl}/report`;
  }
}
