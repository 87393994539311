import Component from "../../../../components"
import "./index.css"
const CategoryModel = ({
    isOpen,
    toggleModel,
    getNewCategoryData,
    createCategory,
    isLoading,
    newCategoryData,
    heading,
    toggleHeading,
    parentCategoryList,
    editCategory
}) => {
    return (
        <Component.DailogBox justifyContent="flex-start" isOpen={isOpen} toggleModel={toggleModel} >
            {
                isLoading ? (
                    <Component.LoadingAnimation color="green" />
                ) : (
                    <>
                        <Component.Row width={Component.deviceWidth - 100} >
                            <h1>  {heading === "Edit" ? <> {heading} </> : (<>  Add {heading}  </>)} </h1>
                        </Component.Row>
                        {
                            heading !== "Edit" && (
                                <>
                                    <Component.Spacer position="top" size={10} />
                                    <Component.Row flexWrap="wrap"  >
                                        <div>
                                            <input type="radio" checked={heading === "Category" ? true : false} clicked="true" value="Category" name="category" onClick={toggleHeading} id="formParentCategory" />
                                            <label htmlFor="formParentCategory"> Parent Category</label>
                                        </div>
                                        <Component.Spacer position="right" size={20} />
                                        <div>
                                            <input type="radio" value="Sub Category" name="category" onClick={toggleHeading} id="formSubCategory" />
                                            <label htmlFor="formSubCategory"> Sub Category</label>
                                        </div>
                                    </Component.Row>
                                </>
                            )
                        }
                        <Component.Spacer position="top" size={20} />
                        <Component.Column  >
                            <h3>Name</h3>
                            <Component.DailogBoxInput value={newCategoryData.name} onChange={getNewCategoryData} type="text" name="name" placeholder="Category Name" />
                            <Component.Spacer position="top" size={10} />
                            <h3>नाम</h3>
                            <Component.DailogBoxInput value={newCategoryData.hindiName} onChange={getNewCategoryData} type="text" name="hindiName" placeholder="श्रेणी का नाम" />
                            {
                                (heading === "Sub Category" || (heading === "Edit" && newCategoryData.categoryId)) && (
                                    <>
                                        <Component.Spacer position="top" size={15} />
                                        <h3> Parent Category </h3>
                                        <select v className="Parent_select" value={newCategoryData.categoryId} name="categoryId" onChange={getNewCategoryData}  >
                                            <option value="">Select Parent Category</option>
                                            {
                                                parentCategoryList.map((category) => {
                                                    return (
                                                        <option value={category._id}>{category.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </>

                                )
                            }
                            <Component.Spacer position="top" size={15} />
                            <h3> Description </h3>
                            <textarea type="text" value={newCategoryData.description} onChange={getNewCategoryData} name="description" placeholder="Desription" ></textarea>
                            <Component.Spacer position="top" size={15} />
                            <h3> विवरण </h3>
                            <textarea type="text" value={newCategoryData.hindiDescription} onChange={getNewCategoryData} name="hindiDescription" placeholder="विवरण" ></textarea>
                            <Component.Spacer position="top" size={15} />
                            <Component.Row alignItems="center" flexWrap="wrap" justifyContent="space-around">
                                <Component.Row alignItems="center" flexWrap="wrap">
                                    <h3>Backgroun Image</h3>/
                                    <Component.Spacer position="right" size={20} />
                                    <input type="file"  accept="image/png, image/jpeg" name="backgroundImage" onChange={getNewCategoryData} />
                                </Component.Row>
                                <Component.Row alignItems="center" flexWrap="wrap">
                                    <h3> Icon </h3>
                                    <Component.Spacer position="right" size={20} />
                                    <input type="file"  accept="image/png, image/jpeg" name="iconImage" onChange={getNewCategoryData} />
                                </Component.Row>
                            </Component.Row>
                            <Component.Spacer position="top" size={45} />

                            <Component.Row justifyContent="flex-end">
                                {
                                    heading === 'Edit' ?
                                        <Component.Button onClick={editCategory} title="Save Changes" />
                                        :
                                        <Component.Button onClick={createCategory} title="Add Category" />
                                }
                            </Component.Row>
                        </Component.Column>

                    </>
                )
            }
        </Component.DailogBox>
    )
}
export default {
    CategoryModel
}