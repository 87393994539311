import Component from "../../../../components"
import "./index.css"
const ScriptModel = ({
    isOpen,
    toggleModel,
    targetQuestion,
    getEditData,
    updateScript
}) => {
    return (
        <Component.DailogBox isOpen={isOpen} justifyContent="flex-Start" toggleModel={toggleModel(null)} >
            <h1> Edit Bot Script</h1>
    
                <Component.Spacer position="top" size={20} />
                <h3> Message in ENglish </h3>
                <textarea type="text" value={targetQuestion.question_en} onChange={getEditData} name="question_en"  ></textarea>
                <Component.Spacer position="top" size={20} />
                <h3> Message in Hindi </h3>
                <textarea type="text" value={targetQuestion.question_hi} name="question_hi" onChange={getEditData}  ></textarea>
                <Component.Spacer position="top" size={20} />
                <Component.Row alignItems="center" flexWrap="wrap">
                    <div>
                        {
                            targetQuestion.options_hi !== undefined &&
                            (
                                <Component.Row>
                                    <h3> Hindi Options</h3>
                                    <Component.Spacer position="rignt" size={10} />
                                    <select onChange={"ok"} name="subCategoryId">
                                        <option value={null}> Options</option>
                                        {
                                            targetQuestion.options_hi.map((option) => {
                                                return (
                                                    <option value={targetQuestion.id} > {option}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Component.Row>
                            )
                        }
                    </div>
                    <Component.Spacer position="left" size={20} />
                    <div>
                        {
                            targetQuestion.options_en !== undefined &&
                            (
                                <Component.Row>
                                    <h3> English Options</h3>
                                    <Component.Spacer position="rignt" size={10} />
                                    <select onChange={"ok"} name="subCategoryId">
                                        <option value={null}> Options</option>
                                        {
                                            targetQuestion.options_en.map((option) => {
                                                return (
                                                    <option value={targetQuestion.id} > {option}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Component.Row>
                            )
                        }
                    </div>
                </Component.Row>
                <Component.Spacer position="top" size={20} />
                <Component.Row justifyContent="flex-end">
                    <Component.Button title="Save Changes" onClick={updateScript} />
                </Component.Row>
        </Component.DailogBox>
    )
}
export default {
    ScriptModel
}