import Component from "../../../components";

const IntrestTable = ({ intrustedData }) => {
  return (
    <table border={1} className="agristickDataTable">
      <tr className="tableHEader">
        <th> S.No</th>
        <th> User Name </th>
        <td> Email </td>
        <td> Phone No </td>
        <td>Intrusted Product </td>
      </tr>
      {intrustedData.map((data, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{data?.user?.name || "User "}</td>
            <td>{data?.user?.email || "No Email Added"}</td>
            <td>{data?.user?.phoneNumber || "No Phone Number Added"}</td>
            <td>{data?.product}</td>
          </tr>
        );
      })}
    </table>
  );
};
export default {
  IntrestTable,
};
