import { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { SoilTestingRepository } from "../../../repository/soilTesting.repository";

const useSoilTestingRequestViewModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [feilds, setFeilds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [requests, setRequests] = useState([]);
  const alert = useAlert();
  const soiltTestingRepository = useMemo(() => new SoilTestingRepository(), []);
  const [selectedFeild, setSelectedFeild] = useState(null);
  const toggleModal = () => {
    if(isOpen){
      setUserName("");
      setSelectedFeild(null);
    }
    setIsOpen((prev) => !prev);

  };
  const getRequests = async () => {
    try {
      setIsLoading(true);
      const apiResponse = await soiltTestingRepository.getSoilTestingRequests();
      setRequests(apiResponse);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  const getUserDetails = async (phoneNumber) => {
    try {
      const apiResponse = await soiltTestingRepository.getUserDetails(
        phoneNumber
      );
      setUserName(apiResponse.userName);
      setFeilds(apiResponse.feilds);
    } catch (err) {
      alert.error(err.message);
    }
  };
  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
    if (event.target.value.length === 10) getUserDetails(event.target.value);
  };
  const handleSelectedFeild = (event) => {
    setSelectedFeild(event.target.value);
    console.log(event.target.value);
  };

  const addRequest = async () => {
    try {
      setIsLoading(true)
      console.log("hit")
      if(selectedFeild && phoneNumber.length ===10 && userName){
         console.log("api")
        const payload = {
          phoneNumber,
         name: userName,
         feildId:selectedFeild
        };
        await soiltTestingRepository.addRequest(payload)
        toggleModal();
       await getRequests();
        setIsLoading(false)
        alert.success("Request added successfully")
      }
    } catch (err) {
      alert.error(err.message);
    }
  };
  const toggleReportForm = (feildId)=>{
    setSelectedFeild(feildId);
  }
  useEffect(() => {
    getRequests();
  }, []);
  return {
    requests,
    isLoading,
    phoneNumber,
    feilds,
    userName,
    handlePhoneNumber,
    isOpen,
    toggleModal,
    handleSelectedFeild,
    addRequest,
    toggleReportForm,
    selectedFeild,
  };
};
export default useSoilTestingRequestViewModal;
