import { Modal } from "@mui/material";
import Component from "../../../components";
import Switch from "react-switch";
import "./index.css";
import { useState } from "react";
const ReportTable = ({ reports, handleViewProfile, toggleUserVisibility }) => {
  return (
    <table border={1} className="agristickDataTable">
      <tr className="tableHEader">
        <th> S.No</th>
        <th> ReportedBy</th>
        <td> Report on </td>
        <td> Reason </td>
        <td>Status</td>
        <th> Action </th>
        <th> View Profile</th>
      </tr>
      {reports?.map((report, index) => {
        const [isDeleted, setIsDeleted] = useState(report.reportedOn.isDeleted);

        return (
          <tr key={index} className="agriStickDataRow">
            <td>{index + 1} </td>
            <td>{report.reportedBy ? report.reportedBy.name : "NOT Addeds"}</td>
            <td>{report.reportedOn ? report.reportedOn.name : "NOT Addeds"}</td>
            <td>{report.reason ? report.reason : "NOT Added"}</td>
            <td>{report.reportedOn.isDeleted ? "Inactive" : "Active"}</td>
            <td>
              <Switch
                onChange={() => {
                  setIsDeleted((prev) => !prev);
                  toggleUserVisibility(report?.reportedOn?._id);
                }}
                checked={!report.reportedOn.isDeleted}
              />
            </td>
            <td>
              <Component.Button
                onClick={() => {
                  handleViewProfile(report.reportedOn._id);
                }}
                title="View Profile"
              />
            </td>
          </tr>
        );
      })}
    </table>
  );
};

const UserProfileModal = (props) => {
  const { isOpen, onClose, selectedUserData } = props;
  if (selectedUserData) {
    const { profile, feeds, connections } = selectedUserData;
    console.log(connections);
    return (
      <Component.DailogBox
        isOpen={isOpen}
        toggleModel={() => {
          onClose();
        }}
      >
        <div className="userProfileContainer">
          <h1>User Profile</h1>

          <Component.Column>
            <Component.Row>
              <image src={profile?.profileImage} className="userProfile" />
              <Component.Column>
                <p> {profile?.name} </p>
                <p> {profile?.email || "email not added"} </p>
                <p> {profile?.phoneNumber} </p>
              </Component.Column>
            </Component.Row>

            <Component.Row style={{ gap: 20 }}>
              <Component.Column justifyContent="center" alignItems="center">
                <p> {connections?.followers || 0}</p>
                <h4 className="margin-0">Followers </h4>
              </Component.Column>
              <Component.Column justifyContent="center" alignItems="center">
                <p> {connections?.following || 0} </p>
                <h4 className="margin-0">Following </h4>
              </Component.Column>
            </Component.Row>

            <h1>Feeds</h1>

            <Component.Row flexWrap="wrap" style={{ gap: 40 }}>
              {feeds.map((feed) => {
                return (
                  <Component.GridCard
                    isFeed={true}
                    key={feed._id}
                    profile={feed.user.profileImage}
                    name={feed.user.name}
                    imgurl={feed.imgurl}
                    videoUrl={feed.videoUrl}
                    mediaType={feed.mediaType}
                    tag={feed.user.userHandler}
                    likes={feed.likes.length}
                    comments={feed.comments.length}
                    description={feed.hindiCaption}
                  />
                );
              })}
            </Component.Row>
          </Component.Column>
        </div>
      </Component.DailogBox>
    );
  }
};

export default {
  ReportTable,
  UserProfileModal,
};
