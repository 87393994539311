import { AppUrl } from "../res/appUrl";
import { ApiService } from "../data/network/apiService";
export class ReportRepository {
  constructor() {
    this.appUrl = new AppUrl();
    this.apiService = new ApiService();
  }
  async getReports() {
    try {
      const url = `${this.appUrl.reportEndPoint}/`;
      return await this.apiService.getGetApiResponse(url);
    } catch (error) {
      throw error;
    }
  }
  async toggleUserVisibility(id) {
    const url = `${this.appUrl.reportEndPoint}/${id}`;
    return await this.apiService.getPutApiResponse(url);
  }
}
