import { useState, useEffect } from "react";
import { UserRepository } from "../../repository/userRepository";
import { useAlert } from "react-alert";
const useUserViewModel = () => {
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("*");
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const userRepository = new UserRepository();
  const alert = useAlert();
  const getUsers = async (pageNo = 1) => {
    try {
      setIsLoading(true);
      const response = await userRepository.getUsers(role, pageNo);
      setPageNo(pageNo);
      setUsers(response.users);
      setIsLoading(false);
    } catch (err) {
      alert.error(err.message);
      setIsLoading(false);
    }
  };
  const changeRole = (event) => {
    try {
      setRole(event.target.value);
    } catch (err) {
      alert.error(err.message);
    }
  };
  const previousPage = async () => {
    try {
      if (pageNo > 1) await getUsers(pageNo - 1);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const nextPage = async () => {
    try {
      if (users.length > 0) await getUsers(pageNo + 1);
    } catch (error) {
      alert.error(error.message);
    }
  };
  useEffect(() => {
    getUsers();
  }, [role]);

  return {
    users,
    changeRole,
    isLoading,
    nextPage,
    pageNo,
    previousPage
  };
};
export default useUserViewModel;
