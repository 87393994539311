import Component from "../../components";
import "./user.css";
import ScreenComponent from "./components";
import useUserViewModel from "./userViewModel";
const Users = () => {
  const { users, changeRole, isLoading, handleDeleteModal, previousPage , nextPage , pageNo} =
    useUserViewModel();
  return (
    <div className="container">
      <Component.Row
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <h1> Users ( {users.length} ) </h1>
        <Component.Row alignItems="center">
          <h3> Roles</h3>
          <Component.Spacer position="right" size={20} />
          <select className="selectUser" onChange={changeRole}>
            <option defaultChecked value="*" id="All">
              {" "}
              All
            </option>
            <option value="Admin" id="Admin">
              Admin
            </option>
            <option value="Moderator" id="Moderator">
              Moderator
            </option>
            <option value="User" id="Userd">
              User
            </option>
          </select>
        </Component.Row>
      </Component.Row>
      <Component.Spacer position="top" size={20} />
      <Component.Row
        flexWrap="wrap"
        style={{ height: Component.deviceHeight * 0.78, overflowY: "scroll" }}
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? (
          <Component.LoadingAnimation color="green" />
        ) : (
          <>
            {users.length > 0 ? (
              <>
                {users.map((user) => {
                  let profile = user.profileImage.split("/")[3];
                  profile = `https://d336izsd4bfvcs.cloudfront.net/${profile}`;
                  return (
                    <Component.CustomCard
                      key={user._id}
                      title={user.name}
                      body={
                        <>
                          <ScreenComponent.CardBody worker={user} />
                        </>
                      }
                      footer={
                        <>
                          <ScreenComponent.CardFooter
                            handleDeleteModal={handleDeleteModal}
                            worker={user}
                            // handleDelete={handleDelete}
                          />
                        </>
                      }
                    />
                  );
                })}
              </>
            ) : (
              <h1> Zeror Users are Created at this moment </h1>
            )}
          </>
        )}
      </Component.Row>
      <div className="pagination">
        <Component.ChildContainer justifyContent="center">
          <Component.PageNavigator
            previousPage={previousPage}
            nextPage={nextPage}
          >
            {pageNo}
          </Component.PageNavigator>
        </Component.ChildContainer>
      </div>
    </div>
  );
};
const NULL = () => {
  return <span style={{ color: "red", margin: "" }}> Empty </span>;
};
export default Users;
// let profile = user.profileImage.split('/')[3]
// profile = `https://d336izsd4bfvcs.cloudfront.net/${profile}`
