import usePostViewModel from "./postViewModel";
import Component from "../../components";
import "./post.css";
const Posts = () => {
  const { post } = usePostViewModel();
  // let image = post.imgUrl?.split("/")[3];
  // image = `https://d336izsd4bfvcs.cloudfront.net/${image}`;
  let url = post.youtubeUrl?.replace("watch?v=", "v/");
  return (
    <div className="container">
      <Component.Column>
        <Component.Row
          justifyContent="center"
          width={Component.deviceWidth - 32}
        >
          {post.youtubeUrl ? (
            <embed
              className="postImage"
              src={url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></embed>
          ) : (
            <img
              className="postImage"
              src={post.imgUrl}
              alt="not able to load"
            />
          )}
        </Component.Row>
        <h1 className="postHeading"> {post.hindiTitle} </h1>
        <Component.Row>
          <div dangerouslySetInnerHTML={{ __html: post.hindiDescription }} />
        </Component.Row>
      </Component.Column>
    </div>
  );
};
export default Posts;
