import React from 'react'
import Component from '../../components'
import ScreenComponent from "./components"
import useEmployeeViewModel from './employeeViewModal'
const EmployeeScreen = () => {
  const {
    isOpen,
    isLoading,
    toggleModal,
    handleEmployeeId,
    handleEmployeeRole,
    handleEmployeeName,
    handleEmloyeeEmail,
    validator,
    createEmployee
  } = useEmployeeViewModel()
  return (
    <div className='container'>
      <Component.Row justifyContent='space-between' alignItems='center' >
      <h1>Employee</h1>
      <Component.Button title="Add Employee"  onClick={toggleModal} />
      </Component.Row>
      <ScreenComponent.AddEmployeeModal 
        isOPen={isOpen}
        isLoading={isLoading}
        toggleModal={toggleModal}
        handleEmployeeId={handleEmployeeId}
        handleEmployeeRole = {handleEmployeeRole}
        handleEmployeeName = {handleEmployeeName}
        handleEmloyeeEmail = {handleEmloyeeEmail} 
        validator={validator}
        createEmployee={createEmployee}
      />
    </div>
  )
}

export default EmployeeScreen
