import { Link } from "react-router-dom"
import Component from "../../../components"
import useFeedCategoryViewModel from "./feedCategoryViewModel"
import ScreenComponent from "./components"
import "./feedCategory.css"
const FeedCategoryScreen = () => {
    const {
        categoryList,
        isLoading,
        isOpen,
        toggleModel,
        category,
        getCategory,
        createCategory,
        toggleEditModel,
        editCategory,
        toggleDeleteRequest,
        deleteCategory,
        showDeleteAlert,
        isUpdate,
        categoryInHindi,
        editCategoryInHindi
    } = useFeedCategoryViewModel()
    return (
        <div className="container">
            <Component.DeleteAlert cancelFunction={toggleDeleteRequest()} dleteFunction={deleteCategory} open={showDeleteAlert} >
                Do you want to delete this Category
            </Component.DeleteAlert>
            <ScreenComponent.FeedCategoryModel
                isOpen={isOpen}
                toggleModel={toggleModel}
                category={category}
                getCategory={getCategory}
                createCategory={createCategory}
                editCategory={editCategory}
                isUpdate={isUpdate}
                categoryInHindi={categoryInHindi}
                editCategoryInHindi={editCategoryInHindi}
            />
            <Component.Row justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <h1> Feed Categories </h1>
                <Component.Row>
                    <Link to="/feeds">
                        <Component.Button title="Feeds" />
                    </Link>
                    <Component.Spacer position="right" size={15} />
                    <Component.Button title="New Category" onClick={toggleModel} />
                </Component.Row>
            </Component.Row>
            <Component.Spacer position="top" size={40} />
            <Component.Row justifyContent="center" alignItems="center" >
                {
                    isLoading ? (
                        <Component.LoadingAnimation color="green" />
                    ) : (

                        <table className="CategoryTable" border="1">
                            <thead>
                                <tr>
                                    <th colSpan="3">
                                        Categories
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categoryList.map((category) => {
                                        return (
                                            <tr key={category._id}>
                                                <td>
                                                    <Component.Row justifyContent="space-between" alignItems="center">
                                                        <span> {category.category} ( {category.categoryInHindi} )  </span>
                                                        <Component.Row alignItems="center">
                                                            <Component.EditButton onClick={toggleEditModel(category._id)} />
                                                            <Component.Spacer position="right" size={15} />
                                                            <Component.DeleteButton onClick={toggleDeleteRequest(category._id)} />
                                                        </Component.Row>
                                                    </Component.Row>
                                                </td>

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    )
                }
            </Component.Row>
        </div>
    )
}
export default FeedCategoryScreen