import Component from "../../components";
import ScreenComponent from "./components/";
import useAgriStickViewModel from "./agriStickViewModel";

const AgriStick = () => {
   const {
      isOpen,
      toggleModel,
      count,
      ids,
      getCount,
      generate,
      isLoading,
      agriStickRecords,
      updateStatus,
      previousPage,
      nextPage,
      pageNo
   } = useAgriStickViewModel();

   return (
      <div className="container">
         <Component.Row justifyContent="space-between" alignItems="center">
            <h1>AgriStick</h1>
            <Component.Button
               title="Generate Ids"
               onClick={toggleModel}
            />
         </Component.Row>
         <ScreenComponent.IdModal
            isOpen={isOpen}
            toggleModel={toggleModel}
            count={count}
            ids={ids}
            getCount={getCount}
            generate={generate}
            isLoading={isLoading}
         />
      {
         isLoading ? 
         <Component.LoadingAnimation color="green" />
         :
          <>
           {
            !agriStickRecords.length  ? 
          <Component.Row  alignItems="center" justifyContent="center">
                   <h1> Nothing To show </h1>
          </Component.Row>
             :
             <Component.Row alignItems="center" justifyContent="center">
             <ScreenComponent.RecordTable
                tableData={agriStickRecords}
                updateStatus={updateStatus}
             />
          </Component.Row>
           }
         <div className="pagination">
        <Component.ChildContainer justifyContent="center" >
          <Component.PageNavigator previousPage={previousPage} nextPage={nextPage}>
            {pageNo}
          </Component.PageNavigator>
        </Component.ChildContainer>
      </div>
          </>
      }
      </div>
   );
};

export default AgriStick;