import { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { AgriStickRepository as AbriStickRepository } from "../../repository/agriStick.repository";
import { FileUploadRepository } from "../../repository/fileUpload.repository";
const useCropViewModel = () => {
  const [crops, setCrops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
  const [newCropCategory, setNewCropCategory] = useState({});
  const [formData, setFormData] = useState(null);
  const [cropCategories, setCropCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("*");
  const [newCropName, setNewCropName] = useState({});
  const fileUploadRepository = new FileUploadRepository();
  const alert = useAlert();
  const toggleModal = () => {
    setIsOpen((prev) => {
      if (prev) {
        setNewCropName("");
        setNewCropCategory("");
      }
      return !prev;
    });
  };
  const toggleAddCategoryModal = () => {
    setIsAddCategoryOpen((prev) => {
      if (prev) setNewCropCategory("");
      return !prev;
    });
  };

  const getNewCropsData = (event) => {
    console.log(event.target.name);
    setNewCropName((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const getCrops = async () => {
    try {
      setIsLoading(true);
      const response = await AbriStickRepository.getCrops(
        selectedCategory !== "*" ? selectedCategory : "*"
      );
      setCrops(response.crops);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  const createCrops = async () => {
    try {
      if (
        newCropName?.name &&
        newCropName?.name_hi &&
        newCropCategory &&
        formData &&
        crops?.every((crops) => crops.name !== newCropName)
      ) {
        toggleModal();
        setIsLoading(true);
        const imgResponse = await fileUploadRepository.uploadImage(formData);
        const payload = {
          category: newCropCategory.cropCategory,
          name: newCropName?.name,
          name_hi: newCropName?.name_hi,
          image: imgResponse.imgurl,
        };
        await AbriStickRepository.createCrops(payload);

        alert.success("Crop Created SuccessFully");
        getCrops();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      alert.error(err.message);
    }
  };
  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          const error = new Error("image size should be less than 1MB");
          error.statusCode = 400;
          throw error;
        }
        const data = new FormData();
        data.append("image", file);
        setFormData(data);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const handleNewCropCategory = (event) => {
    setNewCropCategory((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const createNewCategory = async () => {
    try {
      if (newCropCategory?.name && newCropCategory?.name_hi) {
        const payload = { ...newCropCategory };
        await AbriStickRepository.createCropCategory(payload);
        toggleAddCategoryModal();
        alert.success("successfully created new crop category");
      }
    } catch (err) {
      toggleAddCategoryModal();
      alert.error(err.message);
    }
  };
  const handleSelectedCategory = (event) => {
    setSelectedCategory(event.target.value);
  };
  const getCropCategories = async () => {
    const data = await AbriStickRepository.getCropCategories();
    setCropCategories(data);
  };
  useEffect(() => {
    getCropCategories();
  }, []);
  useEffect(() => {
    getCrops();
  }, [selectedCategory]);

  return {
    crops,
    isLoading,
    isOpen,
    toggleModal,
    getNewCropsData,
    handleFileChange,
    newCropName,
    createCrops,
    isAddCategoryOpen,
    toggleAddCategoryModal,
    handleNewCropCategory,
    createNewCategory: createNewCategory,
    cropCategories,
    handleSelectedCategory: handleSelectedCategory,
  };
};
export default useCropViewModel;
